<template>

  <b-card no-body class="p-1">

    <b-tabs pills content-class="border-top">
      <b-tab active>
        <template #title>
          <span>Admin</span>
        </template>
        <AdminMail/>
      </b-tab>
      <b-tab>
        <template #title>
          <span>Students</span>
        </template>
        <StudentMail/>
      </b-tab>
      <b-tab>
        <template #title>
          <span>Partners</span>
        </template>
        <PartnerMail/>
      </b-tab>
      <b-tab>
        <template #title>
          <span>University Departments</span>
        </template>
        <UniversityMail/>
      </b-tab>
    </b-tabs>

  </b-card>

</template>

<script>
import {BCard, BTabs, BTab} from 'bootstrap-vue'
import {onUnmounted} from '@vue/composition-api'
import store from '@/store'
import storeModule from './store'
import AdminMail from "@/views/system/mail/admin/AdminMail.vue";
import UniversityMail from "@/views/system/mail/university/UniversityMail.vue";
import StudentMail from "@/views/system/mail/student/StudentMail.vue";
import PartnerMail from "@/views/system/mail/partner/PartnerMail.vue";

export default {
  components: {
    BCard,
    BTab,
    BTabs,

    AdminMail,
    UniversityMail,
    StudentMail,
    PartnerMail,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    return {}
  }
}
</script>
